<template>
  <Teaser
    :body-class="`teaser-body-${variant}`"
    :header-class="`teaser-header-${variant}`"
    :style="theme"
  >
    <template #header>
      <LotteryLogo
        v-bind="{ licensedTerritory }"
        :lottery-id="id"
        size="large"
      />

      <LotteryClosingTime
        v-if="variant === 'expressive'"
        class="closing-time"
        :cut-off-time="closingDate"
        variant="expressive"
        @closing-time-is-reached="emit('update-teasers')"
      />
    </template>

    <template #body>
      <div :class="`flex flex-col flex-grow gap-lg w-full body-${variant}`">
        <section class="flex flex-col flex-grow items-center justify-end">
          <strong
            v-if="headline"
            class="text-caption text-secondary700 font-regular"
          >
            {{ headline }}
          </strong>

          <LcLotteryJackpot
            v-bind="jackpotSize"
            locale="en-US"
            :placeholder="
              comingSoon ? t('lottery.teaser.jackpot.placeholder') : undefined
            "
            size="small"
          />

          <strong
            v-if="subtitle"
            class="text-caption text-secondary700 font-regular"
          >
            {{ subtitle }}
          </strong>
        </section>

        <section class="flex gap-xs" :class="{ padding: !showQuickPick }">
          <LcButton
            class="w-full"
            :ghost="showQuickPick"
            no-padding
            :to="{
              path: `/${id.toLowerCase()}`,
              query: {
                newLine: '1',
              },
            }"
          >
            {{ $t('lottery.teaser.button.playnow') }}
          </LcButton>

          <LcButton
            v-if="showQuickPick"
            class="w-full"
            no-padding
            :to="{
              path: `/${id.toLowerCase()}/options`,
              query: {
                quickpick: 'true',
              },
            }"
          >
            {{
              t(
                'lottery.teaser.button.quickpick',
                { count: quickPick!.plays },
                quickPick!.plays,
              )
            }}
          </LcButton>
        </section>
      </div>
    </template>

    <template v-if="variant === 'full'" #footer>
      <div class="flex flex-col gap-3xs">
        <div class="flex items-center gap-2xs text-secondary700 leading-sm">
          <LcIcon name="calendar" size="small" />
          <span class="text-caption">
            {{ t('lottery.teaser.drawdate') }}
          </span>
        </div>
        <DateTime
          v-if="drawDate"
          class="text-caption font-medium"
          :datetime="drawDate"
          format="drawingShort"
        />
      </div>

      <LotteryClosingTime
        :cut-off-time="closingDate"
        @closing-time-is-reached="emit('update-teasers')"
      />
    </template>
  </Teaser>
</template>

<script lang="ts" setup>
import {
  LcButton,
  LcIcon,
  LcLotteryJackpot,
} from '@lottocom/frontend-components'

import type {
  LotteryTeaserEmit,
  LotteryTeaserProps,
} from '~/components/LotteryTeaser/types'

const emit = defineEmits<LotteryTeaserEmit>()

const props = withDefaults(defineProps<LotteryTeaserProps>(), {
  variant: 'full',
})

const { getTranslation, t } = useI18nUtils()

const subtitleLotteryAndTerritorySpecific = computed(() =>
  getTranslation({
    key: `lottery.teaser.subline.${props.id}.${props.licensedTerritory}`,
  }),
)
const subtitleLotterySpecific = computed(() =>
  getTranslation({ key: `lottery.teaser.subline.${props.id}` }),
)

const subtitle = computed(
  () =>
    subtitleLotteryAndTerritorySpecific.value || subtitleLotterySpecific.value,
)

const headline = computed(() => {
  if (subtitle.value) return null

  const key = 'lottery.teaser.headline'
  return getTranslation({ fallback: t(key), key: `${key}.${props.id}` })
})

const showQuickPick = computed(
  () => props.variant === 'full' && !!props.quickPick,
)

const theme = computed(() => {
  const licensedTerritory = props.licensedTerritory.toLowerCase()
  const lotteryId = props.id.toLowerCase()

  return {
    '--lottery-jackpot-color': `var(--${lotteryId}-${licensedTerritory}-color-primary, var(--${lotteryId}-color-primary))`,
  }
})
</script>

<style lang="scss" scoped>
.padding {
  padding: 0 4.5rem;

  @include media-mobile-small {
    padding: 0 2rem;
  }
}

.closing-time {
  min-width: 8rem;
  margin-right: -#{spacing('md')};
}

:deep(.teaser-header-expressive.header) {
  gap: spacing('xs');
}

:deep(.teaser-body-expressive.body) {
  min-height: 12.875rem;
  padding-top: 1.25rem;
}

.body-expressive {
  gap: 0;
  justify-content: space-between;
  height: 100%;

  > * {
    justify-content: unset;
  }
}
</style>
